import React, { Component } from "react";
import "./header.scss";
import Session from "../../../sandbox/session";
import * as Consts from "../../common/constants/const";
import LocationDelivery from "../../../components/location-delivery";
import CurrencySection from "../../../components/currency-section";
import AlertDialog from "../../../components/alert-dialog";
import ItemInformation from "../../../components/item-information";
import { connect } from "react-redux";
import { GetCities, GetCountries } from "../../../reducers";

class Header extends Component {
  constructor() {
    super();

    this.state = {
      Nombre: Session.instance.NombreSelect,
      Telefono: Session.instance.TelefonoSelect,
      EmailAddress: Session.instance.EmailAddressSelect,
      Country: "",
      City: "",
      Direccion: "",
      setCountry: [],
      selectingLocation: false,
      displayBarInformation: true,
      days_delivery: false
    };
  }

  onLogout = () => {
    Session.instance.logout();
  };

  selectCountry = (key, value) => {
    this.setState({
      [key]: value,
    });
    Session.instance.countrySelect = value;
    this.props.GetCities(value.toLowerCase());
  };

  handleChangeData = (key, value) => {
    console.warn("handleChangeData", key, value);
    this.setState({
      [key]: value,
    });
    Session.instance[key+'Select'] = value;
  };

  selectCity = (key, value, pattern, days_delivery) => {
    this.setState({
      [key]: value,
      days_delivery: days_delivery
    });
    Session.instance.citySelect = value;
    Session.instance.days_delivery = days_delivery
  };

  selectLocationDelivery = () => {
    return (
      <div className={"content-select_country"}>
        <ItemInformation
          state="Country"
          pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
          title="País"
          data={Session.instance.countries}
          value={this.state.Country}
          typeInput="select"
          type="text"
          valid={true}
          changeValue={this.selectCountry}
        />
        <ItemInformation
          state="City"
          pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
          title="Ciudad"
          data={Session.instance.cities}
          value={this.state.City}
          typeInput="select"
          type="text"
          valid={true}
          changeValue={this.selectCity}
        />

        <ItemInformation
          state="Direccion"
          pattern={/^[a-zA-Z][a-zA-Z ]{1,50}$/}
          title="Dirección"
          lengthMax={50}
          value={this.state.Direccion}
          type="text"
          valid={true}
          changeValue={this.handleChangeData}
        />

      </div>
    );
  };


  basicInformationClient = () => {
    return (
      <div className={""}>
        <div className="d-f fd-c py-2">
          <span className="title-auxiliary">Informacion de compra</span>
          <small className="title-description">La informacion es requerida para realizar compras</small></div>
       
        <ItemInformation
          state="Nombre"
          pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
          title="Nombre"
          value={this.state.Nombre}
          type="text"
          valid={true}
          changeValue={this.handleChangeData}
        />
        <ItemInformation
          state="Telefono"
          pattern={/^[0-9]{10}$/}
          title="Telefono"
          value={this.state.Telefono}
          type="text"
          valid={true}
          changeValue={this.handleChangeData}
        />

        <ItemInformation
          state="EmailAddress"
          pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/}
          title="Correo Electronico"
          lengthMax={50}
          value={this.state.EmailAddress}
          type="text"
          valid={true}
          changeValue={this.handleChangeData }
        />

      </div>
    );
  };

  informationNeedToBuy = () => {
    return (
      <div className="content-select_country" >
        {this.selectLocationDelivery()}
        {this.basicInformationClient()}
      </div>
    )
  }

  cancelFilter = () => {
    this.setState({
      selectingLocation: !this.state.selectingLocation,
    });
  };

  confirmFilter = () => {
    this.cancelFilter();
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.countries && this.state.selectingLocation) {
      this.cancelFilter();
    }
    if (
      nextProps.cities &&
      Session.instance.cities !== nextProps.cities &&
      nextProps.cities.length > 0
    ) {
      Session.instance.cities = nextProps.cities;
    }
  }

  UNSAFE_componentWillMount() {
    if (Session.instance.countrySelect !== null) {
      this.props.GetCities(Session.instance.countrySelect.toLowerCase());

      this.setState({
        Country: Session.instance.countrySelect,
      });
    }

    if (Session.instance.citySelect !== "") {
      this.setState({
        City: Session.instance.citySelect,
      });
    }


    if (Session.instance.DireccionSelect !== "") {
      this.setState({
        Direccion: Session.instance.DireccionSelect,
      });
    }

    if (Session.instance.days_delivery !== "") {
      this.setState({
        days_delivery: Session.instance.days_delivery,
      });
    }

  }

  render() {
    const { days_delivery } = this.state;
    return (
      <header className={"body-header"}>
        {this.state.selectingLocation ? (
          <AlertDialog
            title={Consts.txSelCoun}
            description={Consts.txDescLocation}
            content={this.informationNeedToBuy()}
            onCancel={() => {
              this.cancelFilter();
            }}
            onConfirm={() => this.confirmFilter()}
          />
        ) : (
          <div></div>
        )}

        {this.state.displayBarInformation ? (
          <div className={"bar-information"}>
            <span className={"tx-bar ta-c"}>
              <div className="ic ic-dinero" aria-hidden="true"></div>
              {Consts.txBar1}
            </span>
            <CurrencySection updatePrices={this.props.updatePrices}>
              {" "}
            </CurrencySection>
            <LocationDelivery
              Country={this.state.Country}
              City={this.state.City}
              onSelectLocation={() => {
                this.cancelFilter();
              }}
            />
           <>
            {days_delivery && (
              <abbr title={`Llega en ${days_delivery} días hábiles`}>
                <div className="d-f jc-c ai-c">
                  Llega {days_delivery === '1' ? 'Hoy' : `en ${days_delivery} día${days_delivery > 1 ? 's' : ''}`}
                </div>
              </abbr>
             
            )}
          </>
            <span className={"tx-bar ta-c tt-i"}>
              <div className="ic ic-cambio" aria-hidden="true"></div>
              {Consts.txBar2}
            </span>
            <a className={"tx-presentation d-f"} href={"#/presentation"}>
              <abbr
                title={`Navegar: Ver toda la informacion relacionada a ${Consts.txTitle}`}
              >
                <h1 className={"tx-bar ta-c m-n"}>${Consts.txTitle}</h1>
              </abbr>
            </a>
            {/* <div className={"d-f jc-c ai-c"}>
              <span className={" ta-c"}>
                <div className="ic ic-service-c" aria-hidden="true"></div>
                BOG&nbsp;<a href={`tel:${Consts.txBar3V}`}> {Consts.txBar3} </a>
                &nbsp; | COL &nbsp;
                <a href={`tel:${Consts.txBar4V}`}> {Consts.txBar4} </a>{" "}
              </span>

              <a className={"op-5 ml--1 col-th"} href={"#/blog"}>
                <h5 className={"m-n col-th"}>Blog</h5>
              </a>
            </div> */}
            <div className="ct-info-sec">
  {/* Se añadió un aria-label para mejorar la accesibilidad del link */}
  <span className="tt-lc ta-c mr-4">
    <a className="col-th" href={`mailto:${Consts.emailContact}`} aria-label="Contact email">
      {Consts.emailContact}
    </a>
  </span>

  {/* Muestra el icono de tienda solo si no hay tienda activa */}
  {!this.props.store && (
    <div className="icons-rs">
      <section className="icons-rs">
        {/* Se añade aria-label al enlace de la tienda para mejorar la accesibilidad */}
        <a href="/#/tienda" id="shopp" className="icons-rs shop" aria-label="Shop">
          {/* Se utiliza "sr-only" para ocultar visualmente el texto pero seguir siendo accesible */}
          <span className="d-n">Tienda</span>
        </a>
        {/* Se maneja la lógica para mostrar el contador sólo si hay items */}
        {this.props.storeItems && this.props.storeItems.length > 0 && (
          <div className="counter-shop">
            {this.props.storeItems.length}
          </div>
        )}
      </section>
    </div>
  )}
</div>

          </div>
        ) : (
          <></>
        )}
        <header id="application-header" className="header">
          <div
            className={`head-view ${this.props.store ? "fd-r" : ""} ${this.props.store ? "hv-store" : ""
              }`}
          >
            <a href={"/"}>
              <div className="content-title">
                <h2 className="title-artesaniasgb">{Consts.txTitle}</h2>
              </div>
            </a>

            {this.props.header === null ? (
              <h1 className="subtitle">{Consts.txMsgGen}</h1>
            ) : (
              this.props.header
            )}
            {window.location.hash.indexOf("tienda") === -1 ? (
              <div className="content-icons">
                <div className="icons-rs">
                  <div
                    onClick={() => {
                      window.open(Consts.txInstagram);
                    }}
                    className="icon ins"
                  ></div>
                  <div
                    onClick={() => {
                      window.open(Consts.txWhatsapp);
                    }}
                    className="icon wapp"
                  ></div>
                  <div
                    onClick={() => {
                      window.open(Consts.txFacebook);
                    }}
                    className="icon fbook"
                  ></div>
                  <div className="icon space"></div>
                </div>
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </header>
      </header>
    );
  }
}

export default connect(
  (state) => ({
    cities: state.setInfoData.cities,
    countries: state.setInfoData.countries,
  }),
  { GetCountries, GetCities }
)(Header);
